<template>
<section class="space-y-4">
  <offline-action-alert v-if="!isOnline" />
  <QuickInfo/>
  <asom-alert v-if="error" variant="error" :error-message="error" />
  <article class="space-y-2">
    <h3 class="subheader">
      Cash Order Details
    </h3>
    <asom-card>
      <section>
        <span class="text-sm" v-if="cashOrderOptions.length === 0">No Cash Order found</span>
        <asom-form-field
          v-else
          :label="'Cash Order'"
          :state="inputStates('formData.cashOrderId')"
          error="Cash Order is required"
        >
          <asom-input-radio-group
            class="mt-3"
            v-model="formData.cashOrderId"
            :state="inputStates('formData.cashOrderId')"
            :options="cashOrderOptions"
          />
        </asom-form-field>
        <div class="grid md:grid-cols-3 gap-4" v-if="cashOrderDetails != null">
          <asom-form-field label="Request By Station">
            <span class="mt-3">{{cashOrderDetails.requestorName}}</span>
          </asom-form-field>
          <asom-form-field label="Order Amount">
            <span class="mt-3">${{cashOrderDetails.orderAmount}}</span>
          </asom-form-field>
        </div>
      </section>
    </asom-card>
    <br>
    <h3 class="subheader">
      Transfer Details
    </h3>
    <asom-card>
      <section>
        <add-cashbag
          @add-bag="addCashBag"
        />
        <asom-client-table
          :filterable="false"
          :pagination="false"
          :columns="[
            'no',
            'bagNo',
            'amount',
            'remarks',
            'action'
          ]"
          :data="formData.cashBagList"
        >
          <template v-slot:header_bagNo>Cash Bag Serial No.</template>
          <template v-slot:no="slotScoped">
            {{slotScoped.rowIndex + 1}}
          </template>
          <template v-slot:action="slotScoped">
            <asom-button variant="error" size="sm"
              outline
              rounded="none"
              icon="trash"
              text=""
              @click="removeBag(slotScoped.rowData.tempId)">
            </asom-button>
          </template>
        </asom-client-table>
        <span class="text-sm text-red-700" v-if="inputStates('formData.cashBagList') === 'error'">
          Cash Bag List cannot be empty.
        </span>
        <asom-form-field label="Description" class="md:w-1/2">
          <asom-input-textarea v-model="formData.description" />
        </asom-form-field>
      </section>
    </asom-card>
    <br>
    <h3 class="subheader">
      Confirmation
    </h3>
    <asom-card>
      <aside>
        <article class="grid sm:grid-cols-1 md:grid-cols-2">
          <asom-form-field label="Staff (Donor)">
            <p class="mt-3 font-bold">{{userName}}</p>
          </asom-form-field>
          <asom-form-field :label="'Staff (Recipient)'"
            required
            :state="inputStates('formData.recipient')"
            error="Recipient user is required."
          >
            <asom-input-text
              v-model="formData.recipient"
              :state="inputStates('formData.recipient')"
            />
          </asom-form-field>
        </article>
        <article class="grid sm:grid-cols-1 md:grid-cols-2">
          <asom-form-field label="Signature (Donor)"
            :state="inputStates('formData.donorSignature')"
            error="Donor Signature is required"
          >
            <asom-signature-pad
              v-model="formData.donorSignature"
              :width="300"
            />
          </asom-form-field>
          <asom-form-field label="Signature (Recipient)"
            :state="inputStates('formData.recipientSignature')"
            error="Recipient Signature is required"
          >
            <asom-signature-pad
              v-model="formData.recipientSignature"
              :width="300"
            />
          </asom-form-field>
        </article>
      </aside>
      <aside class="flex flex-1 items-end justify-end">
        <asom-button
          text="Confirm Transaction"
          variant="primary"
          @click="onConfirmBtnClick"
        />
      </aside>
    </asom-card>
  </article>
</section>

<asom-modal
  title="Confirm"
  v-model="showModal"
  :dismissible="false"
>

  <p class="pt-4">Are you sure you would like to proceed?</p>
  
  <div class="flex flex-row-reverse pt-4">
  <div class="pl-4">
    <asom-button
      @click="onSubmit"
      :disabled="isSubmitting"
      :loading="isSubmitting"
      text="OK"
    />
  </div>
  <div>
    <asom-button
      @click="onCloseModal"
      text="Cancel"
      variant="secondary"
    />
  </div>
  </div>
</asom-modal>
</template>

<script>
import get from 'lodash.get'
import { mapGetters } from 'vuex';
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { imageDataToBlob } from '@/helpers/imageDataToBlob';
import { uploadFile } from '@/services/file.service';
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";
import { createStationTransfer } from "@/services/cashManagement.service";
import QuickInfo from "../../_QuickInfo.vue";
import AddCashbag from './AddCashbag.vue';
import OfflineActionAlert from "@/layout/OfflineActionAlert"

export default {
  mixins: [inputStates],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  components: {
    QuickInfo,
    AddCashbag,
    OfflineActionAlert,
  },
  data() {
    return {
      isSubmitting: false,
      showModal: false,
      error: null,
      formData: {
        cashOrderId: null,
        cashBagList: [],
        description: "",
        recipient: null,
        donorSignature: null,
        recipientSignature: null,
        donorSignatureId: null,
        recipientSignatureId: null,
      }
    }
  },
  validations: {
    formData: {
      cashOrderId: {
        required
      },
      cashBagList: {
        required,
        minLength: minLength(1),
      },
      recipient: {
        required,
      },
      donorSignature: {
        required
      },
      recipientSignature: {
        required,
      }
    }
  },
  mounted() {
    this.$store.dispatch('cashManagement/cashOrders/loadCashOrderList');
  },
  computed: {
    ...mapGetters({
      userName: 'auth/userName',
      cashOrderList: 'cashManagement/cashOrders/getList',
      cashOrderOptions: 'cashManagement/cashOrders/getListFromOtherStationsAsOptions',
      rosterPlanDate: 'currentRoster/currentRosterPlanDate',
      rosterDws: 'currentRoster/currentRosterShift',
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    cashOrderDetails() {
      return this.cashOrderList.find(({ cashOrderId }) => cashOrderId == this.formData.cashOrderId);
    },
    submissionData() {
      return {
        cashOrderId: this.formData.cashOrderId,
        cashBagList: [ ...this.formData.cashBagList ],
        description: this.formData.description,
        staffRecipientName: this.formData.recipient,
        donorSignatureId: this.formData.donorSignatureId,
        recipientSignatureId: this.formData.recipientSignatureId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      };
    },
    offlineSubmissionData() {
      return {
        ...this.submissionData,
        donorSignature: this.formData.donorSignature,
        recipientSignature: this.formData.recipientSignature,
      }
    },
  },
  methods: {
    addCashBag(bagData) {
      this.formData.cashBagList = [
        ...this.formData.cashBagList,
        {
          ...bagData,
        }
      ]
    },
    removeBag(bagTempId) {
      this.formData.cashBagList = this.formData.cashBagList.filter(({ tempId }) => tempId !== bagTempId);
    },
    onSubmit() {
      if (this.isOnline) {
        this.createStationTransferAsync();
      } else {
        this.$store.dispatch('offlineData/stationTransfer', this.offlineSubmissionData);
        this.showModal = false;
        this.$router.push({name: 'AFC Main Page'})
      }
    },
    async createStationTransferAsync() {
      this.error = null;
      this.isSubmitting = true;
      const isSignatureUploaded = this.formData.donorSignatureId !== null &&
        this.formData.recipientSignatureId !== null;
      if (!isSignatureUploaded) {
        const signatureBlob1 = imageDataToBlob(this.formData.donorSignature, 'donor_signature');
        const signatureBlob2 = imageDataToBlob(this.formData.recipientSignature, 'contractor_signature');
        const uploadResult = await uploadFile([ signatureBlob1, signatureBlob2], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        if (!uploadResult.success) {
          this.error = uploadResult.payload;
          this.isSubmitting = false;
          this.$scrollTop();
          return;
        }
        this.formData.donorSignatureId = get(uploadResult, 'payload.files.0.fileId');
        this.formData.recipientSignatureId = get(uploadResult, 'payload.files.1.fileId');
      }
      
      const result = await createStationTransfer(this.submissionData);
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push({name: 'AFC Main Page'})
      }
    },
    onConfirmBtnClick() {
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.formData.$invalid) {
        this.error = "Please complete all required fields";
        this.$scrollTop();
        return;
      }
      this.showModal = true;
    },
    onCloseModal() {
      this.showModal = false;
    },
  }
};
</script>
